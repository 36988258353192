<template>
  <div class="SDC">
    <div class="bannang"><img src="https://resources.holowits.com/static_image/sdc/pc_banner.jpg" alt=""></div>
    <div class="banner-move"><img src="https://resources.holowits.com/static_image/sdc/mobile_banner.jpg" alt=""></div>
    <div v-html="news.content" class="cansta content">
    </div>
  </div>
</template>

<script>
import {detail} from "@/api/news"
export default {
  data() {
    return {
      news: {},
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      detail({id: '1675789879880974336'}).then(res => {
        this.news = res.data.data
      })
    }
  }
}
</script>

<style scoped lang="less">

/deep/.content{

  .person_pic{
    text-align: center;
    padding: 20px 0px 0px;
    img{
      width: 25%;
    }
  }
  .person_notes{
    text-align: center;
    padding-top: 30px
  }
}

.divider{
  width: 100%;
  height: 0px;
  opacity: 1;
  border: 1px solid #F1F1F1;
  margin-bottom: 30px;
}

/deep/ .txt_line {
  padding-top: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
}

.header {
  margin-top: 60px;
  .title{
    font-size: 40px;
    font-weight: bold;
  }
  .publish_date {
    font-size: 16px;
    display: block;
    padding: 24px 0px;
    color: #666666;
    line-height: 19px;
  }
}

/deep/ .box-card-news__body {
  margin-bottom: 20px;
}

.about {
  margin: 30px 0px;

  .about-line {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    .about-line__pre {
      width: 25%;
      display: inline-block;
      height: 4px;
      background: #C7000B;
      opacity: 1;
    }
    .about-line__last{
      display: inline-block;
      width: 100%;
      height: 4px;
      background: #E2E2E2;
      opacity: 1;
    }
    .title {
      font-size: 26px;
      color: #3f3f3f;
    }
  }
  .about__holowits{
    font-size: 22px;
    font-weight: bold;
    color: #000000;
    line-height: 26px;
  }

  .about__content{
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 34px;
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .header {
    .title{
      font-size: 24px;
    }
  }
  /deep/.content{
    .person_pic{
      img{
        width: 50%;
      }
    }
  }
  .about {
    .about-line{
      .about-line__pre {
        width: 95%;
      }
    }
    .about__holowits {
      font-size: 18px;
    }
  }
}

@media (max-width: 750px) {
  /deep/.wscnph {
    width: 100%;
    height: 360px;
    display: block;
    object-fit: contain;
    // object-position: right;
    border-radius: 8px;
  }
}
</style>

<style lang="less">
.cansta {
  padding-top: 50px;
  width: 1170px;
  margin: auto;
}
@media (max-width: 991px) {
  .SDC {
    padding: 0 28/7.5vw;
  }
  .bannang {
    display: none;
  }
  .banner-move {
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .cansta {
    width: 750px;
  }
}
@media (min-width: 991px) {
  .banner-move {
    display: none;
  }
  .bannang {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .cansta {
    width: 1170px;
  }
}
</style>
